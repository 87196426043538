import {useQuery} from 'react-query';
import {getQueryParam} from 'src/utils/url';

const fetchRelatedStyles = async (styleId, colorId) => {
  const iframer = getQueryParam('iframer');
  const queryParams = new URLSearchParams();
  queryParams.set('PK', colorId);
  if (iframer) {
    queryParams.set('iframer', iframer);
  }

  const response = await fetch(
    `/products/styles/${styleId}/related_styles.json?${queryParams.toString()}`
  );
  if (!response.ok) {
    throw new Error('Failed to fetch related styles', {
      colorId,
      response,
      styleId
    });
  }
  return response.json();
};

const useRelatedStyles = (styleId, colorId, options = {}) => {
  return useQuery({
    queryFn: () => fetchRelatedStyles(styleId, colorId),
    queryKey: ['related_styles', styleId, colorId],
    ...options
  });
};

export default useRelatedStyles;
