import {
  Box,
  Carousel,
  useBreakpoint,
  useMobileBreakpoint
} from '@customink/pigment-react';
import React, {useContext, useEffect, useState} from 'react';
import CurrentSelectedColorContext from 'product_details_page/Context/colorContext';
import ProductContext from 'product_details_page/Context/productContext';
import Image from '../../components/Image';

export default function ProductCarousel({
  buildColorImage,
  isPreviewUploadTestEnabled
}) {
  const [slider, setSlider] = useState();
  const product = useContext(ProductContext);
  const {currentSelectedColor: currentColor} = useContext(
    CurrentSelectedColorContext
  );
  const {carouselProps, name: styleName} = product || {};

  if (!carouselProps || !currentColor) {
    return null;
  }

  const isMobile = useMobileBreakpoint();
  const isMd = useBreakpoint('md');
  const isLg = useBreakpoint('lg');
  const breakpointCarouselHeight = () => {
    if (isMobile || isLg) return '450px';
    if (isMd) return '380px';
    return '490px';
  };

  const buildColorImageSet = (color, index = 0) => {
    if (isPreviewUploadTestEnabled) {
      return `${buildColorImage(color, 412, isPreviewUploadTestEnabled)[index]} 1x, ${buildColorImage(color, 659, isPreviewUploadTestEnabled)[index]} 2x`;
    }
    return `${buildColorImage(color, 412)} 1x, ${buildColorImage(color, 659)} 2x`;
  };

  const carouselImages = () => {
    const images = [
      <>
        {isPreviewUploadTestEnabled && (
          <Image
            id={`${currentColor.id}`}
            alt={`${styleName}-default`}
            src={buildColorImage(currentColor)[1]}
            srcSet={buildColorImageSet(currentColor, 1)}
            placeholderImage
            placeholderImageId={`${currentColor.id}-default-placeholder`}
            placeholderImageAlt={`${styleName}-default-placeholder`}
            placeholderImageSrc={
              buildColorImage(currentColor, 412, isPreviewUploadTestEnabled)[0]
            }
            placeholderImageStyle={{height: '100%'}}
            placeholderImageSrcSet={buildColorImageSet(currentColor, 0)}
          />
        )}
        {!isPreviewUploadTestEnabled && (
          <img
            alt={`${styleName}-default-preview`}
            src={buildColorImage(currentColor)}
            srcSet={buildColorImageSet(currentColor)}
          />
        )}
      </>
    ];
    images.push(
      ...carouselProps.mainContent.map((media, idx) => {
        return (
          <img
            alt={styleName}
            src={media.src}
            srcSet={media.srcset}
            // eslint-disable-next-line react/no-array-index-key
            key={`carsl-img-${idx}`}
          />
        );
      })
    );
    return images;
  };

  const thumbnailImages = () => {
    const images = [
      <>
        {isPreviewUploadTestEnabled && (
          <Image
            id={`${currentColor.id}-thumbnail`}
            alt={`${styleName}-default`}
            src={buildColorImage(currentColor)[1]}
            srcSet={buildColorImageSet(currentColor, 1)}
            placeholderImage
            placeholderImageId={`${currentColor.id}-thumbnail-placeholder`}
            placeholderImageAlt={`${styleName}-thumbnail-placeholder`}
            placeholderImageSrc={
              buildColorImage(currentColor, 412, isPreviewUploadTestEnabled)[0]
            }
            placeholderImageStyle={{height: '100%', width: '100%'}}
            placeholderImageSrcSet={buildColorImageSet(currentColor, 0)}
            spinnerSize="small"
          />
        )}
        {!isPreviewUploadTestEnabled && (
          <img
            alt={`${styleName}-default`}
            src={buildColorImage(currentColor)}
            srcSet={buildColorImageSet(currentColor)}
          />
        )}
      </>
    ];
    images.push(
      ...carouselProps.thumbnails.map((media, idx) => {
        return (
          <img
            alt={styleName}
            src={media.src}
            srcSet={media.srcset}
            // eslint-disable-next-line react/no-array-index-key
            key={`media-img-${idx}`}
          />
        );
      })
    );

    return images;
  };

  const onCreated = (_slider) => {
    setSlider(_slider);
  };

  useEffect(() => {
    if (slider) {
      slider.moveToIdx(0);
    }
  }, [currentColor, slider]);

  return (
    <Box
      sx={{
        display: 'flex',
        flex: {lg: '4.5', sm: '1'},
        flexDirection: 'column',
        minWidth: '0',
        overflow: 'clip',
        paddingBottom: {md: '40px', sm: 0}
      }}>
      <Carousel
        sx={{
          '.arrow': {
            position: 'relative',
            top: 0
          },
          ...(!isMobile && {
            position: 'sticky',
            top: '2.5rem'
          })
        }}
        sliderHeight={breakpointCarouselHeight()}
        slides={carouselImages()}
        onCreated={onCreated}
        {...(!isMobile && {
          thumbnails: thumbnailImages(),
          thumbSize: thumbnailImages().length > 6 || isMd ? 'small' : 'medium'
        })}
      />
    </Box>
  );
}
