import {Box, RefreshIcon} from '@customink/pigment-react';
import React, {useState} from 'react';

const Image = ({
  id,
  alt,
  src,
  srcSet,
  placeholderImage = false,
  placeholderImageId,
  placeholderImageAlt,
  placeholderImageSrc,
  placeholderImageSrcSet,
  placeholderImageStyle = {},
  spinnerSize = 'large'
}) => {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <>
      <Box
        id={placeholderImageId}
        data-testid={placeholderImageId}
        sx={{
          ...(!isLoading && {display: 'none'}),
          ...(isLoading && {display: 'block'})
        }}>
        <RefreshIcon
          sx={{
            animation: 'spin 1000ms infinite linear',
            left: 0,
            top: 0,
            bottom: 0,
            margin: 'auto',
            position: 'absolute',
            right: 0,
            verticalAlign: 'middle',
            zIndex: 100
          }}
          fontSize={spinnerSize}
        />
        {placeholderImage && (
          <img
            style={placeholderImageStyle}
            alt={placeholderImageAlt}
            src={placeholderImageSrc}
            srcSet={placeholderImageSrcSet}
          />
        )}
      </Box>
      <img
        id={id}
        data-testid={id}
        onLoad={(event) => {
          setIsLoading(false);
        }}
        style={{
          ...(isLoading && {display: 'none'}),
          ...(!isLoading && {display: 'block'})
        }}
        alt={alt}
        src={src}
        srcSet={srcSet}
      />
    </>
  );
};

export default Image;
